import { useEffect, useState } from 'react';

const useFilteredList = (list, onFilter = (list) => list) => {
  const [filter, setActiveFilter] = useState();
  const [filteredList, setFilteredList] = useState(list);

  useEffect(() => {
    setFilteredList(filter ? onFilter(list, filter) : list);
  }, [filter]);
  return [filteredList, setActiveFilter];
};

export default useFilteredList;
