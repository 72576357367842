import React from 'react';
import styled from 'styled-components';

const Label = styled.label`
  position: relative;

  transition: opacity 350ms;

  > select {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    border-radius: 0;
    padding: 10px 60px 10px 20px;
  }

  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.secondary};

  &:hover {
    opacity: 0.7;
    .icon {
      transform: translateY(3px);
    }
  }

  > .icon {
    position: absolute;
    pointer-events: none;
    top: 50%;
    right: 20px;
    height: 20px;
    width: 20px;
    margin-top: -10px;

    transition: transform 350ms;

    > svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      fill: ${({ theme }) => theme.secondary};
    }
  }
`;

const Select = ({ label = 'Velg', onChange = () => {}, options = [], children }) => (
  <Label htmlFor="filter">
    <select onChange={onChange} id="filter">
      <option value="">{label}</option>
      {options.map((value) => (
        <option value={value} key={value}>
          {value}
        </option>
      ))}
    </select>
    {children}
  </Label>
);

export default Select;
