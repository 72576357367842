import React from 'react';
import Button from '@components/Button';
import { useMemo } from 'react';
import styled from 'styled-components';

const PaginationButton = styled(Button)`
  transition: opacity 350ms, background-color 350ms;
  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover:not(:disabled) {
    /* background-color: ; */
    opacity: 0.7;
  }
`;

const PaginationWrap = styled.div`
  display: flex;
  justify-content: center;

  > div {
    flex-grow: 1;
  }
`;

const usePagination = (currentPage, pageCount) => {
  const allPages = useMemo(() => Array.from({ length: pageCount }, (_, i) => i + 1), [pageCount]);
  if (pageCount <= 4) return allPages;
  const right = currentPage + 1;
  const left = currentPage - 1;

  const filteredPages = allPages.filter((i) => {
    if (currentPage === 1) return i === currentPage || i === right || i === right + 1;
    if (currentPage === pageCount) return i === currentPage || i === left || i === left - 1;
    return i === currentPage || i === right || i === left;
  });

  if (currentPage < pageCount - 1) {
    filteredPages.push(pageCount);
  }

  if (currentPage > 2) {
    filteredPages.unshift(1);
  }
  return filteredPages;
};

const Pagination = ({ currentPage, navigate, onNavigate = () => {}, maxPages }) => {
  const pages = usePagination(currentPage, maxPages);

  return (
    maxPages > 1 && (
      <PaginationWrap>
        <PaginationButton
          disabled={currentPage === 1}
          onClick={() => {
            navigate(currentPage - 1);
            onNavigate(currentPage - 1);
          }}
        >
          {'<'}
        </PaginationButton>
        {pages.map((index) => (
          <PaginationButton
            key={index}
            disabled={index === currentPage || index === '...'}
            onClick={() => {
              navigate(index);
              onNavigate(index);
            }}
          >
            {index}
          </PaginationButton>
        ))}
        <PaginationButton
          disabled={currentPage === maxPages}
          onClick={() => {
            navigate(currentPage + 1);
            onNavigate(currentPage + 1);
          }}
        >
          {'>'}
        </PaginationButton>
      </PaginationWrap>
    )
  );
};

export default Pagination;
