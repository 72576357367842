import { useCallback, useEffect, useMemo, useState } from 'react';

const paginateList = (list, pageNumber, itemsPerPage) => {
  return list.slice((pageNumber - 1) * itemsPerPage, pageNumber * itemsPerPage);
};

const usePaginatedList = (list = [], { itemsPerPage = 10, startPage = 1 } = {}) => {
  const [currentPage, setCurrentPage] = useState(startPage);
  const [paginatedList, setPaginatedList] = useState([]);
  const maxPages = useMemo(() => Math.ceil(list.length / itemsPerPage), [list, itemsPerPage]);

  useEffect(() => {
    currentPage && setPaginatedList(paginateList(list, currentPage, itemsPerPage));
  }, [itemsPerPage, startPage, currentPage, list]);

  const navigate = useCallback(
    (pageNumber) => {
      if (pageNumber < 0 || pageNumber > maxPages) return;
      setCurrentPage(pageNumber);
    },
    [itemsPerPage, maxPages]
  );
  return [paginatedList, { navigate, maxPages, currentPage }];
};

export default usePaginatedList;
