import React from 'react'
import { useObservable } from '../hooks/useObservable';
import { HeaderHeightSubject } from './Header';

export const HeaderPadding = () => {

    const height = useObservable(HeaderHeightSubject, 0);

    return (
    <div style={{ height: height, flexGrow: 0, flexShrink: 0 }} />
    )
}

export default HeaderPadding
