import { CleanButton } from '@components/CleanButton';
import { above, below } from '@styles/breakpoints';
import { fontSize } from '@styles/fonts';
import styled from 'styled-components';

const Button = styled(CleanButton)`
  ${below.small`
    padding: 15px 15px;
  `}
  
  ${above.small`
    padding: 15px 20px;
  `}

  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.secondary};
  font-size: ${fontSize('small')};

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.primary};
  }
`;

export default Button;
